/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import { publicPath, routerMode } from '@/config'
import Layout from '@/vab/layouts'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  // {
  //   path: '/register',
  //   component: () => import('@/views/register'),
  //   meta: {
  //     hidden: true,
  //   },
  // },
  // {
  //   path: '/callback',
  //   component: () => import('@/views/callback'),
  //   meta: {
  //     hidden: true,
  //   },
  // },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'BasicData',
    component: Layout,
    redirect: '/basicFiles',
    meta: {
      title: '基础资料',
      icon: 'book-3-fill',
    },
    children: [
      {
        path: 'customsCodeManage',
        name: 'customsCodeManage',
        component: () => import('@/views/customsCodeManage'),
        meta: {
          title: '中国海关编码字典',
        },
      },
      {
        path: 'basicFiles',
        name: 'basicFiles',
        component: () => import('@/views/basicFiles'),
        meta: {
          title: '关务基础信息管理',
        },
      },
      {
        path: 'customerMapping',
        name: 'customerMapping',
        component: () => import('@/views/customerMapping'),
        meta: {
          title: '客户与报关申报映射',
        },
      },
      {
        path: 'customerMappingDraft',
        name: 'customerMappingDraft',
        component: () => import('@/views/customerMappingDraft'),
        meta: {
          title: '客户与报关申报映射(待补充)',
        },
      },
      {
        path: 'highRiskDataManagement',
        name: 'highRiskDataManagement',
        component: () => import('@/views/highRiskDataManagement'),
        meta: {
          title: '高风险资料管理',
        },
      },
    ],
  },
  // {
  //   path: '/',
  //   name: 'Chedule',
  //   component: Layout,
  //   redirect: '/customerMapping',
  //   meta: {
  //     title: '客户与关务档案映射',
  //     icon: 'money-euro-box-line',
  //     levelHidden: true,
  //     noKeepAlive:true
  //   },
  //   children: [
  //     {
  //       path: 'customerMapping',
  //       name: 'customerMapping',
  //       component: () => import('@/views/customerMapping'),
  //       meta: {
  //         title: '客户与关务档案映射',
  //         icon: 'money-euro-box-line',
  //         noKeepAlive:true
  //       },
  //     },
  //   ],
  // },
  {
    path: '/clearanceList',
    name: 'ListManagement',
    component: Layout,
    redirect: '/clearanceList',
    meta: {
      title: '报关清单列表管理',
      icon: 'file-user-line',
    },
    children: [
      {
        path: 'clearanceList',
        name: 'clearanceList',
        component: () => import('@/views/clearanceList'),
        meta: {
          title: '报关清单列表',
        },
      },
      // {
      //   path: 'ces',
      //   name: 'clearanceListOne',
      //   component: () => import('@/views/clearanceList/ces'),
      //   meta: {
      //     title: '报关清单列表',
      //     noKeepAlive: true
      //   },
      // },
      {
        path: 'draftCopy',
        name: 'draftCopy',
        component: () => import('@/views/draftCopy'),
        meta: {
          title: '底单列表',
        },
      },
      // {
      //   path: 'indexCeshi',
      //   name: 'IndexCeshi',
      //   component: () => import('@/views/clearanceList/indexCeshi'),
      //   meta: {
      //     title: '表格测试',
      //   },
      // },
      // {
      //   path: 'indexCeshiTwo',
      //   name: 'IndexCeshiTwo',
      //   component: () => import('@/views/clearanceList/ceshi'),
      //   meta: {
      //     title: '表格测试2',
      //   },
      // },
    ],
  },
  // {
  //   path: '/',
  //   name: 'Chedule',
  //   component: Layout,
  //   redirect: '/draftCopy',
  //   meta: {
  //     title: '底单列表',
  //     icon: 'clockwise-2-fill',
  //     levelHidden: true,
  //     noKeepAlive:true
  //   },
  //   children: [
  //     {
  //       path: 'draftCopy',
  //       name: 'draftCopy',
  //       component: () => import('@/views/draftCopy'),
  //       meta: {
  //         title: '底单列表',
  //         icon: 'clockwise-2-fill',
  //         noKeepAlive:true
  //       },
  //     },
  //   ],
  // },
  {
    path: '/taskManage',
    name: 'SystemSettings',
    component: Layout,
    redirect: '/taskManage',
    meta: {
      title: '系统设置',
      icon: 'settings-5-fill',
    },
    children: [
      {
        path: 'taskManage',
        name: 'taskManage',
        component: () => import('@/views/taskManage'),
        meta: {
          title: '任务管理',
          noKeepAlive: true,
        },
      },
      {
        path: 'authLoginLog',
        name: 'authLoginLog',
        component: () => import('@/views/authLoginLog'),
        meta: {
          title: '登录日志',
        },
      },
      {
        path: 'baseSystemLog',
        name: 'baseSystemLog',
        component: () => import('@/views/baseSystemLog'),
        meta: {
          title: '操作日志',
        },
      },
    ],
  },

  // {
  //     path: '/authLoginLog',
  //     name: 'Chedule333',
  //     component: Layout,
  //     redirect: '/authLoginLog',
  //     meta: {
  //       title: '登录日志',
  //       icon: 'cloudy-2-line',
  //       levelHidden: true,
  //       noKeepAlive:true
  //     },
  //     children: [
  //       {
  //         path: 'authLoginLog',
  //         name: 'authLoginLog',
  //         component: () => import('@/views/authLoginLog'),
  //         meta: {
  //           title: '登录日志',
  //           icon: 'cloudy-2-line',
  //           noKeepAlive:true
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     path: '/baseSystemLog',
  //     name: 'Chedule5565',
  //     component: Layout,
  //     redirect: '/baseSystemLog',
  //     meta: {
  //       title: '操作日志',
  //       icon: 'layout-grid-fill',
  //       levelHidden: true,
  //       noKeepAlive:true
  //     },
  //     children: [
  //       {
  //         path: 'baseSystemLog',
  //         name: 'baseSystemLog',
  //         component: () => import('@/views/baseSystemLog'),
  //         meta: {
  //           title: '操作日志',
  //           icon: 'layout-grid-fill',
  //           noKeepAlive:true
  //         },
  //       },
  //     ],
  //   },

  {
    path: '*',
    redirect: '/coreReport',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
