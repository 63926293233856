<template>
  <div class="table-container">
    <el-transfer
      v-model="value"
      class="transfer-panel"
      :data="data"
      :filter-method="filterMethod"
      filter-placeholder="请输入搜索内容"
      filterable
      target-order="push"
      :titles="titles"
      @change="handleChange"
      @right-check-change="rightHandleChange"
    >
      <div slot-scope="{ option }" class="table-row">
        <div>
          {{ option.label }}
        </div>
        <div>
          {{ fixList.includes(option.key) ? '固定' : '' }}
        </div>
      </div>
    </el-transfer>
    <div>
      <div class="options">
        <el-button
          class="transfer-footer"
          :disabled="rightValue.length !== 1"
          icon="el-icon-arrow-up"
          size="mini"
          type="primary"
          @click="upChange"
        />
        <el-button
          class="transfer-footer"
          :disabled="rightValue.length !== 1"
          icon="el-icon-arrow-down"
          size="mini"
          type="primary"
          @click="downChange"
        />
        <el-button
          class="transfer-footer"
          :disabled="rightValue.length !== 1"
          size="mini"
          type="primary"
          @click="topChange"
        >
          置顶
        </el-button>
        <el-button
          class="transfer-footer"
          :disabled="!rightValue.length"
          size="mini"
          type="primary"
          @click="switchFixedEvent"
        >
          固定/取消
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import XEUtils from 'xe-utils'

  export default {
    props: {
      columns: {
        type: Array,
      },
      $table: Object,
    },

    data() {
      let hideValue = []
      let columnsMap = {}
      let fixList = []
      let topFix = 0
      let bottomFix = 0
      let hasOption = false
      const generateData = () => {
        const data = []
        this.columns.forEach((column) => {
          const isChecked = column.renderVisible
          columnsMap[column.field] = column
          if (column.renderFixed) {
            fixList.push(column.field)
          }
          if (
            ['selectOption', 'indexOption'].includes(column.field) ||
            ['seq', 'checkbox'].includes(column.type)
          ) {
            topFix++
          }
          if (column.field === 'option') {
            bottomFix++
          }
          if (column.field === 'option') {
            hasOption = true
          }
          let label = column.title
          if (column.field === 'selectOption' || column.type === 'checkbox') {
            label = '勾选框'
          }
          data.push({
            label,
            renderFixed: column.renderFixed,
            key: column.field,
            disabled:
              ['option', 'selectOption', 'indexOption'].includes(
                column.field
              ) || ['seq', 'checkbox'].includes(column.type),
          })
          if (isChecked) {
            hideValue.push(column.field)
          }
        })
        return data
      }

      return {
        columnsMap,
        data: generateData(),
        value: hideValue,
        fixList,
        titles: ['隐藏', '显示'],
        topFix,
        bottomFix,
        hasOption,
        loadingExPort: false,
        filterMethod(query, item) {
          return item.label.indexOf(query) > -1
        },
        rightValue: [], //右侧勾选的值
      }
    },
    methods: {
      switchFixedEvent() {
        this.rightValue.forEach((item) => {
          if (this.fixList.includes(item)) {
            this.cancelFixedData([item])
          } else {
            this.changeFixedOption(this.columnsMap[item], 'left')
            this.fixList.push(item)
          }
        })
        this.sortList(this.value)
      },
      cancelFixedData(cancelData) {
        cancelData.forEach((item) => {
          this.changeFixedOption(this.columnsMap[item], '')
          if (this.fixList.includes(item)) {
            let index = this.fixList.indexOf(item)
            this.fixList.splice(index, 1)
          }
        })
      },
      cancelFixedEvent() {
        this.cancelFixedData(this.rightValue)
        this.sortList(this.value)
      },
      rightHandleChange(rightValue) {
        this.rightValue = rightValue
      },
      handleChange(value, direction, movedKeys) {
        if (direction === 'left') {
          this.rightValue = []
          this.cancelFixedData(movedKeys)
        }
        if (movedKeys.length > 0) {
          movedKeys.forEach((key) => {
            this.changeCheckboxOption(this.columnsMap[key])
          })
        }
        this.sortList(value)
      },
      upChange() {
        const index = this.value.indexOf(this.rightValue[0])
        // 如果元素存在且不是第一个元素
        if (index > 0) {
          // 使用 splice 方法移除元素
          const [item] = this.value.splice(index, 1)
          // 将元素插入到前一个位置
          this.value.splice(index - 1, 0, item)

          const fixIndex = this.fixList.indexOf(this.rightValue[0])

          if (fixIndex > this.topFix) {
            // 使用 splice 方法移除元素
            const [findItem] = this.fixList.splice(fixIndex, 1)
            // 将元素插入到前一个位置
            this.fixList.splice(fixIndex - 1, 0, findItem)
          }
          this.sortList(this.value)
        }
      },
      downChange() {
        // 找到要移动的元素的索引
        const index = this.value.indexOf(this.rightValue[0])

        // 如果元素存在且不是最后一个元素
        if (index !== -1 && index < this.value.length - this.bottomFix) {
          // 使用 splice 方法移除元素
          const [item] = this.value.splice(index, 1)
          // 将元素插入到后一个位置
          this.value.splice(index + 1, 0, item)

          const fixIndex = this.fixList.indexOf(this.rightValue[0])

          // 如果元素存在且不是最后一个元素
          if (
            fixIndex !== -1 &&
            fixIndex < this.fixList.length - this.bottomFix
          ) {
            // 使用 splice 方法移除元素
            const [findItem] = this.fixList.splice(fixIndex, 1)
            // 将元素插入到后一个位置
            this.fixList.splice(fixIndex + 1, 0, findItem)
          }
          this.sortList(this.value)
        }
      },
      topChange() {
        const index = this.value.indexOf(this.rightValue[0])
        // 如果元素存在且不是第一个元素
        if (index > 0) {
          // 使用 splice 方法移除元素
          const [item] = this.value.splice(index, 1)
          // 将元素插入到前一个位置
          this.value.unshift(item)

          const fixIndex = this.fixList.indexOf(this.rightValue[0])

          if (fixIndex > this.topFix) {
            // 使用 splice 方法移除元素
            const [findItem] = this.fixList.splice(fixIndex, 1)
            // 将元素插入到前一个位置
            this.fixList.splice(this.topFix, 0, findItem)
          }
          this.sortList(this.value)
        }
      },
      sortList(value) {
        const { customColumnList } = this.$table
        let list = []
        const insert = (item) => {
          const cIndex = customColumnList.findIndex(
            (column) => column.field === item
          )
          customColumnList.splice(cIndex, 1)
          customColumnList.push(this.columnsMap[item])
          list.push(item)
        }
        this.fixList.forEach((item) => {
          if (value.includes(item) && item !== 'option') {
            insert(item)
          }
        })
        value.forEach((key) => {
          if (!this.fixList.includes(key)) {
            insert(key)
          }
        })
        if (this.hasOption) {
          insert('option')
        }
        this.value = list
      },
      handleOptionCheck(column) {
        const { customColumnList } = this.$table
        const matchObj = XEUtils.findTree(
          customColumnList,
          (item) => item === column
        )
        if (matchObj && matchObj.parent) {
          const { parent } = matchObj
          if (parent.children && parent.children.length) {
            parent.visible = parent.children.every((column) => column.visible)
            parent.halfVisible =
              !parent.visible &&
              parent.children.some(
                (column) => column.visible || column.halfVisible
              )
            this.handleOptionCheck(parent)
          }
        }
      },
      changeCheckboxOption(column) {
        const $xetable = this.$table
        const { customOpts } = $xetable
        const isChecked = !column.renderVisible
        if (customOpts.immediate) {
          XEUtils.eachTree([column], (item) => {
            item.visible = isChecked
            item.renderVisible = isChecked
            item.halfVisible = false
          })
          $xetable.handleCustom()
          $xetable.saveCustomStore('update:visible')
        } else {
          XEUtils.eachTree([column], (item) => {
            item.renderVisible = isChecked
            item.halfVisible = false
          })
        }
        this.handleOptionCheck(column)
        $xetable.checkCustomStatus()
      },
      changeFixedOption(column, colFixed) {
        const $xetable = this.$table
        const { customOpts } = $xetable
        if (customOpts.immediate) {
          XEUtils.eachTree([column], (col) => {
            col.fixed = colFixed
            col.renderFixed = colFixed
          })
          $xetable.handleCustom()
          $xetable.saveCustomStore('update:fixed')
        } else {
          XEUtils.eachTree([column], (col) => {
            col.renderFixed = colFixed
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .transfer-panel {
    ::v-deep {
      .el-transfer-panel {
        width: 260px;
        .el-transfer-panel__item.is-disabled {
          margin-right: 30px;
        }
        .el-transfer-panel__item {
          margin-right: 30px !important;
        }
      }
    }
  }
  .table-container {
    display: flex;
    align-items: flex-end;
    gap: 15px;
  }
  .table-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .options {
    display: flex;
    gap: 5px;
    flex-direction: column;
    ::v-deep {
      .el-button {
        margin-left: 10px;
      }
    }
  }
</style>
